// Colors
$grey-1: #a7a9ac;
$grey-2: #808285;
$grey-pixi: #ecedf1;
$blue-1: #75c5ef;
$blue-2: #3795d2;
$blue-3: #1f6faf;
$blue-4: #164e87;
$blue-5: #19355f;
$blue-6: #081c3e;
$blue-pastel: #edf8fd;
$blue-cool: #dbe3ec;
// $blue-pastel: #cad5da;
// $blue-pastel: #6eafcd;
$naranja: #ff8300;
$naranja-pastel: #fff7ef;
$naranja-cool: #e9d0b4;
$naranja-mex: #fcf1ea;
$contact-color: #f2f4f8;
$product1: #fcf1ea;
$product2: #e8e9ea;
$product3: #bdcfdd;

$nav-height: 3.5em;

// breakpoints
$bp-small: 24.15;
$bp-medium: 48; // 768
$bp-large: 60.25; // 964
$bp-xlarge: 75; // 1200

// sizes
$font-size-1: 1;
$font-size-2: 1.414;
$font-size-3: 1.999;
$font-size-4: 2.827;
$font-size-5: 3.998;
$font-size-6: 5.653;
$font-size-min1: 0.707;
$font-size-min2: 0.5;
