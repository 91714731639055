.landing {
  height: calc(100vh - #{$nav-height});
  position: relative;
  max-width: 90em;
  // width: 100vw;
  margin: 0 auto;
  margin-top: $nav-height;
  // margin-bottom: 10vh;
  background-image: url("/static/img/hero/nathhero-768w.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  // @media screen and (min-width: 500px) {
  //   background-image: url("/static/img/hero/nathhero-550w.jpg");
  // }
  // @media screen and (min-width: 550px) {
  //   background-image: url("/static/img/hero/nathhero-640w.jpg");
  // }
  // @media screen and (min-width: 640px) {
  //   background-image: url("/static/img/hero/nathhero-768w.jpg");
  // }
  @media screen and (min-width: 768px) {
    background-image: url("/static/img/hero/nathhero-960w.jpg");
  }
  @media screen and (min-width: 960px) {
    background-image: url("/static/img/hero/nathhero-1200w.jpg");
  }
  @media screen and (min-width: 1200px) {
    background-image: url("/static/img/hero/nathhero-maxw.jpg");
  }

  .row {
    // border: solid red;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 90em;
    height: 100%;
    flex-direction: column;
    height: 100%;
  }

  .textCol {
    position: relative;
    height: 38%;
    // background-color: rgba($blue-4, 0.7);
    // background-color: rgba($blue-5, 0.6);
    // border: solid green;

    .container {
      display: flex;
      align-items: flex-end;
      position: relative;
    }
  }

  .imgCol {
    height: 62%;
    // border: solid blue;
  }

  &--card {
    position: absolute;
    width: 75%;
    transform: translateY(-50%);
    top: 100%;
    // height: fit-content;
    padding: 2em;
    background: rgba($blue-4, $alpha: 1);
    // background: rgba($color: #ffffff, $alpha: 0.3);
    // backdrop-filter: blur(15px);
    // border-radius: 15px;
    // border: solid 2px transparent;
    // background-clip: padding-box;
    box-shadow: 10px 10px 10px rgba($color: #2e3644, $alpha: 0.03);
  }

  &--whatsapp {
    display: flex;
    text-decoration: none;
    color: white;
    // background-color: $naranja;
    background-color: #1bd741;
    // height: 1em;
    margin-top: 1em;
    font-size: 1.25em;
    gap: 0.5em;
    padding: 0.5em;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &__icon {
      --size: 1.5em;
      // height: 2.5em;
      flex-shrink: 0;
      height: var(--size);
      width: var(--size);

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  &--title {
    line-height: 1.125;
    line-height: 1.5;
    font-size: 1.5em;
    color: white;
    font-weight: 300;
    transform-origin: topbottom;
    // border: solid white;

    span {
      color: $naranja;
      font-weight: 600;
    }
  }
}

@media screen and (min-width: calc(#{$bp-small}*1em)) {
  .landing {
    &--title {
      font-size: 1.7em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .landing {
    &--title {
      font-size: 2em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .landing {
    .row {
      flex-direction: row;
    }

    .textCol,
    .imgCol {
      height: 100%;
    }

    .textCol {
      width: 38%;
    }

    .imgCol {
      width: 62%;
    }

    &--card {
      // height: 12em;
      width: 30em;
      top: 50%;
      right: -160px;
    }

    &--whatsapp {
      width: fit-content;
      padding: 0.5 2em;
    }

    &--title {
      font-size: 2em;
      margin-bottom: 2em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-xlarge}*1em)) {
  .landing {
    margin-top: 0;
    overflow: hidden;

    &--back {
      height: calc(100vh - #{$nav-height});
      margin-top: $nav-height;
      // width: 100vw;
      // background-image: url('/static/img/clinic1.jpeg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      overflow: hidden;
    }

    &--card {
      // height: 15em;
    }

    &--title {
      font-size: 2em;
      line-height: 1.5;
    }
  }
}

@media screen and (min-width: calc(#{$bp-xlarge}*1em)) and (max-height: 800px) {
  .landing {
    &--title {
      font-size: 2em;
    }
  }
}
