.circle {
  position: absolute;
  z-index: 20;
  bottom: 0;
  right: -10%;
  height: 150px;
  width: 150px;
  transform: translateY(50%);
  border-radius: 50%;
  text-transform: uppercase;
  color: $naranja;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &--text {
    position: absolute;
    width: 100%;
    height: 100%;
    user-select: none;

    span {
      position: absolute;
      left: 50%;
      font-size: 1em;
      transform-origin: 0 75px;
      user-select: none;
    }
  }

  &--logo {
    position: absolute;
    z-index: 21;
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $naranja;
    box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .circle {
    height: 200px;
    width: 200px;
    top: 0;
    transform: translateY(-30%);

    &--text {
      span {
        font-size: 1.2em;
        transform-origin: 0 100px;
      }
    }

    &--logo {
      height: 80px;
      width: 80px;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .circle {
    left: 0;
    // top: 20%;
    top: auto;
    bottom: 0;
    transform: translateX(-60%) translateY(0);
  }
}
