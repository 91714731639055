.footer {
  background-color: $blue-4;
  color: white;

  .container {
    padding: 3em 1em;
  }

  &--brand {
    margin-bottom: 1em;

    img {
      width: 30%;
    }
  }

  &--about {
    font-weight: 300;
    line-height: 1.25;
    font-family: 'Palanquin', sans-serif;
    max-width: 20em;
    font-size: 0.85em;
  }

  &--social {
    margin-top: 2em;
    display: flex;
    gap: 2em;

    &__link {
      display: inline-block;
      width: 2.5em;

      height: 2.5em;

      img {
        @include imgSize;
      }
    }
  }

  &--links {
    margin-top: 2em;
    list-style: none;

    &__item {
      margin-bottom: 0.5em;
      font-size: 0.85em;
    }

    a {
      color: white;
      transition: all 0.3s ease 50ms;
      cursor: pointer;

      &:hover {
        color: $naranja;
      }
    }
  }

  &--end {
    background-color: $blue-5;

    .container {
      padding: 1em;
    }

    &__item {
      display: flex;
      font-weight: 700;
      line-height: 1.25;
      margin-top: 1.5em;
      transition: all 0.3s ease 50ms;
      font-size: 0.85em;

      &-icon {
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 1em;
      }

      .location_icon {
        background-image: url('/static/img/icons/location_icon.svg');
        background-size: contain;
        width: 2em;
        height: 2em;
      }

      .phone_icon {
        background-image: url('/static/img/icons/phone_icon.svg');
        width: 1.5em;
        height: 1.5em;
      }

      .mail_icon {
        background-image: url('/static/img/icons/mail_icon.svg');
        background-size: cover;
        width: 1.5em;
        height: 1em;
      }

      &:first-of-type {
        margin-top: 0;
        font-weight: 400;
      }

      &:last-of-type {
        font-weight: 700;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-small}*1em)) {
  .footer {
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .footer {
    .container {
      padding: 3em 5em;
    }

    &--row {
      display: flex;
      justify-content: space-between;
    }

    &--links {
      margin-top: 0;
    }

    &--end {
      .container {
        padding: 1em 5em;
      }

      &__item {
        align-items: center;

        .location_icon {
          width: 2em;
          height: 2em;
        }

        .mail_icon {
          width: 2em;
          height: 2em;
        }
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .footer {
    &--brand {
      img {
        width: 12rem;
      }
    }

    &--about {
      font-size: 1em;
    }

    &--end {
      .container {
        padding: 1em 5em;
      }

      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__item {
        margin-top: 0;
        transition: all 0.3s ease 50ms;

        &:hover {
          transform: scale(1.1);
        }

        &:last-of-type {
          margin-top: 0;
        }

        .location_icon {
          width: 2em;
          height: 1.5em;
        }

        .phone_icon {
        }

        .mail_icon {
          width: 1.5em;
          height: 1em;
        }
      }
    }
  }
}
