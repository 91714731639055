.benefits {
  padding: 2em 0;
  height: calc(100vh - #{$nav-height});
  overflow: hidden;
  background-color: $blue-cool;

  &--header {
    padding: 0.5em 0;
    font-family: 'Palanquin', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    color: $blue-5;
    border-bottom: solid #444444 1px;
    text-align: right;
  }

  &--list {
    list-style: none;

    &__item {
      padding: 2em 0;
      font-weight: 300;
      align-items: center;
      font-family: 'Palanquin', sans-serif;
      color: #444444;
      color: black;
      border-bottom: solid #444444 1px;

      &:first-of-type {
        margin-top: 2em;
      }

      &:last-of-type {
        border-bottom: none;
      }

      &-big {
        line-height: 1;
        font-size: 2.2em;
        font-weight: 500;
      }

      &-small {
        margin-top: 0;
        line-height: 1;
        font-size: 1.5em;
      }

      @media screen and (min-height: 500px) and (max-height: 600px) {
        // font-size: calc(#{$font-size-2}* 0.9em);
      }

      img {
        height: calc(#{$font-size-2}* 0.707em);
      }

      &-text {
        margin-left: 0.5em;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .benefits {
    height: auto;
    padding-bottom: 6em;

    &--list {
      &__item {
        padding: 3em 0;

        &-big {
          font-size: 4em;
        }

        &-small {
          font-size: 2.5em;
        }
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .benefits {
    &--list {
      &__item {
        &-big {
          font-size: 4.5em;
        }
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-xlarge}*1em)) {
  .benefits {
    &--container {
      display: flex;
      justify-content: center;
    }

    &--list {
      width: fit-content;

      &__item {
        &-big {
          font-size: 4.5em;
        }
      }
    }
  }
}
