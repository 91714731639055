.sale {
  position: fixed;
  z-index: 20;
  bottom: 1em;
  right: 1em;
  // height: 70px;
  // width: 70px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  // padding-top: 10px;
  cursor: pointer;
  overflow: hidden;
  animation: groAnim 3s ease-in-out infinite;

  .discount {
    position: absolute;
    top: calc(50% + 8px);
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background: linear-gradient(45deg, #ff0047, #6eff00);
    animation: saleAnim 5s linear infinite;
    z-index: 0;
  }

  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    padding: 3em 1em;
    background-color: rgba(black, 0.6);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  &--container {
    position: relative;
    background-color: white;
    background: linear-gradient(135deg, #fd890e, #fa192e);
    color: white;
    padding: 1em 1em 1em 1em;
    // max-width:
    border-radius: 5px;
  }

  &--closeBtn {
    position: absolute;
    top: 1em;
    right: 1em;
    height: 1.5em;
    width: 1.5em;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    img {
      @include imgSize;
    }
  }

  &--title {
    font-weight: 400;
    font-size: 2.5em;
    // width: fit-content;
    width: 3em;
    line-height: 1;
    padding: 0 0.125em;
  }

  .desde {
    position: absolute;
    top: 0.5rem;
    right: 3rem;
    color: white;
    color: green;
  }

  &--price {
    position: absolute;
    margin-left: auto;
    display: block;
    width: fit-content;
    top: 1rem;
    right: 3rem;
    font-size: 2em;
    font-weight: 700;
    padding: 0.5em;
    border-radius: 50%;
    border: dashed white;

    &::before {
      content: 'Desde';
      position: absolute;
      top: 0;
      right: 2.5rem;
      font-weight: 300;
      font-size: 1.25rem;
    }

    &::after {
      content: 'por estudio';
      position: absolute;
      bottom: -0.25rem;
      left: 2rem;
      // left: 50%;
      height: 2em;
      width: 10em;
      // width: fit-content;
      // border: solid white;
      // transform: translateX(-50%);
      font-size: 0.5em;
      font-weight: 300;
    }
  }

  &--time {
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 0.7em;
    margin-top: 2em;
    // text-align: justify;
  }

  &--list {
    list-style: none;
    border-top: solid white thin;
    padding-top: 1.5em;

    li {
      display: flex;
      margin-bottom: 1em;

      p {
        &:first-of-type {
          flex-basis: 2em;
          flex-shrink: 0;
        }
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .sale {
    bottom: 2em;
    right: 2em;
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .sale {
    // bottom: 3em;
    // right: 3em;
    height: 120px;
    width: 120px;
  }
}

@keyframes saleAnim {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

@keyframes groAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
