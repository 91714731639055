.goto {
  border-radius: 5.8rem;
  background-color: $naranja;
  color: #fff;
  padding: 0 0.6rem 0 2.2rem;
  display: inline-flex;
  height: 3em;
  height: 2em;

  &--link {
    // font-size: 0.9375vw;
    display: flex;
  }

  &--svg {
    height: 1em;
    width: 1em;
  }
}
