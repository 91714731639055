@mixin bodyText {
  font-family: 'Palanquin';
  font-weight: 300;
  color: #444444;
  // color: #000000;
}

@mixin imgSize {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
