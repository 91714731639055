.certificados {
  padding: 2em 0;
  padding-bottom: 6em;
  overflow: hidden;

  &--title {
    font-size: 3em;
    font-weight: 300;
    color: $blue-4;
    line-height: 1;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    span {
      font-size: 0.707em;
      color: $blue-3;
    }
  }

  &--photo {
    margin-top: 1em;

    img {
      @include imgSize;
    }
  }

  &--description {
    @include bodyText;
    margin-top: 1em;
  }

  &--list {
    list-style: none;
    margin-top: 3em;
  }

  &--item {
    height: 5em;
    cursor: pointer;
    border-bottom: solid thin #444444;
    overflow: hidden;

    &:last-of-type {
      border-bottom: none;
    }

    &__header {
      display: flex;
      padding: 1em 0;
      height: 5em;
    }

    &__number {
      font-size: 2em;
      color: #444444;
      font-weight: 300;
      margin-right: 1.5em;
    }

    &__logo {
      img {
        object-fit: contain;
        height: 100%;
      }
    }

    &__content {
      padding: 1em;
      background-color: $blue-pastel;
      margin-bottom: 1em;

      a {
        display: block;
        margin-bottom: 1em;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .certificados {
    &--item {
      &__logo img {
        transition: all 0.3s ease 50ms;
      }

      &__number {
        transition: all 0.3s ease 50ms;
      }

      &:hover {
        // border-bottom-color: white;
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        .certificados--item__number {
          font-weight: 700;
          color: $naranja;
        }

        .certificados--item__logo img {
          transform: scale(1.05);
        }
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .certificados {
    &--row {
      display: flex;
      gap: 3em;
    }

    &--photo {
      width: 50%;
    }

    &--container {
      width: 50%;
    }
  }
}
