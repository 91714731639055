.contact {
  position: fixed;
  top: 0;
  left: -100%;
  // left: 0;
  height: 100vh;
  width: 100%;
  background-color: $contact-color;
  z-index: 101;

  .container {
    padding: 3em;
  }

  &--close {
    position: absolute;
    display: flex;
    align-items: center;
    top: 1.5em;
    right: 1.5em;
    height: 1.6em;
    gap: 0.5em;
    color: $blue-5;
    cursor: pointer;

    &__icon {
      height: 1.6em;

      img {
        @include imgSize;
      }
    }
  }

  &--title {
    font-size: 2em;
    font-weight: 500;
    color: $blue-5;
    margin-top: 1.25em;
    line-height: 1.25;
  }

  &--subtitle {
    margin-top: 0.5em;
    font-size: 1.25em;
    font-weight: 300;
    color: $blue-5;
    line-height: 1.25;
  }

  &--promise {
    font-family: 'Palanquin';
    font-weight: 700;
    font-size: 0.8em;
    line-height: 1;
    text-decoration: underline;
    color: $blue-5;
    // color: $naranja;
    margin-top: 1em;
  }

  &--form {
    margin-top: 4em;

    &__inputPortal {
      height: 5em;
      overflow: hidden;
    }

    &__inputGroup {
      height: 5em;

      &-label {
        font-family: 'Palanquin';
        color: $blue-5;

        span {
          font-weight: 700;
        }
      }

      &-input {
        margin-top: 0.5em;
        padding: 0.25em 1em;
        font-family: 'Palanquin';
        font-size: 1em;
        width: 100%;
        border-radius: 7px;
        border: none;

        &.error {
          border: solid #ff5019;
        }
      }

      &-submit {
        position: relative;
        display: block;
        background-color: $naranja;
        color: white;
        border-radius: 5px;
        border: none;
        width: 100%;
        font-size: 1em;
        margin-top: 0.5em;
        padding: 0.5em 1em;
        cursor: pointer;
        overflow: hidden;
        z-index: 103;
        transition: all 0.2s ease;

        &:hover {
          &::before {
            transform: translateX(100%);
          }
        }

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: -100%;
          height: 100%;
          width: 100%;
          background-color: $blue-1;
          z-index: -1;
          transition: all 0.2s ease;
        }
      }
    }

    &__buttonRow {
      display: flex;
      justify-content: space-between;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5em;
      width: 9em;
      border-radius: 50px;
      border-color: $blue-5;
      padding: 5px;
      margin-top: 2em;
      background-color: white;
      cursor: pointer;
      transition: all 0.3s ease 50ms;

      img {
        @include imgSize;
      }

      &_prev {
        flex-direction: row-reverse;

        &.inactive {
          opacity: 0;
          cursor: default;
          user-select: none;
        }
      }

      &_next {
        &.inactive {
          opacity: 0;
          cursor: default;
          user-select: none;
        }
      }

      &:hover {
        background-color: $blue-5;

        .contact--form__button-text {
          color: white;
        }
      }

      &-text {
        color: $blue-5;
        text-decoration: underline;
      }

      &-img {
        background-color: $naranja;
        border-radius: 50%;
        height: 100%;
        width: 2.5em;
        height: 2.5em;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    #producto {
      display: none;
    }
  }

  &--error {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    padding: 1em;
    font-family: 'Palanquin', sans-serif;
    background-color: #ff5019;
    color: white;
    text-align: center;

    &__text {
      line-height: 1.25;
    }
  }

  &--overlay {
    background-color: rgba($blue-5, 0.3);
    backdrop-filter: blur(4px);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    &_final {
      z-index: 105;
    }

    &__messageContainer {
      transform: translateY(-50%);
      background-color: green;
      color: white;
      padding: 2em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .contact {
    width: 50%;
    left: -50%;
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .contact {
    width: 30em;
    left: -30em;
  }
}
