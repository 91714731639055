.ctaButton {
  // border: solid $naranja 3px;
  position: relative;
  border-radius: 7px;
  background-color: $naranja;
  color: white;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.5em 1em;
  overflow: hidden;
  transition: all 0.2s ease;

  &_secondary {
    background-color: white;
    font-weight: 700;
    text-transform: uppercase;
  }

  &:hover {
    &::before {
      transform: translateX(100%);
    }
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background-color: $blue-1;
    z-index: -1;
    transition: all 0.2s ease;
  }
}
