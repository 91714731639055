.solutions {
  background-color: #fff9f5;
  background-color: #f3f4f7;
  padding: 2em 0;
  padding-bottom: 0;
  position: relative;

  .container {
    position: relative;
    // border: solid red;
  }

  &--overlay {
    position: fixed;
    z-index: 223;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vh;
    border-radius: 50%;
    background-color: $naranja;
    transform-origin: center;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      outline: none;
      border: none;
      width: 100%;
      height: 100%;
    }

    &__container {
      position: relative;
      height: 100%;
      width: 100%;
      padding-top: 56.25%;
    }

    &__closeBtn {
      position: fixed;
      top: 2em;
      right: 2em;
      height: 2.5em;
      width: 2.5em;
      outline: none;
      border: none;
      cursor: pointer;

      @media screen and (min-width: calc(#{$bp-small}*1em)) and (max-height: 800px) {
        height: 4em;
        width: 4em;
        background-color: $blue-4;
        border-radius: 50%;
        padding: 1em;
      }

      @media screen and(min-width: calc(#{$bp-xlarge}*1em)) {
        height: 4em;
        width: 4em;
        background-color: $blue-4;
        border-radius: 50%;
        padding: 1em;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  &--title {
    font-size: 3em;
    font-weight: 300;
    padding: 1em 0;
    text-align: center;
    color: $blue-5;
  }

  &--product {
    overflow: hidden;
    background-color: $product1;
    padding: 2em 1em;
    height: calc(100vh - #{$nav-height});

    @media screen and (max-height: 500px) {
      height: auto;
    }

    &_middle {
      background-color: $product2;
    }

    &_last {
      background-color: $product3;
    }

    &__header {
      padding: 0.5em 0;
      border-bottom: solid #444444 1px;
    }

    &__title {
      color: $blue-5;
      text-align: right;
      font-family: "Palanquin", sans-serif;
      font-weight: 700;
      letter-spacing: 1px;
    }

    &__photo {
      position: relative;
      width: 100%;
      margin-top: 0em;
      margin-bottom: 1em;

      &-main {
        &.color {
          background-color: #1f1d38;
        }
      }

      &-main,
      video,
      img {
        box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.25);
        @include imgSize;
      }

      .white {
        background-color: white;
      }
    }

    &__strapline {
      margin-top: 2em;
      font-size: calc(#{$font-size-2}* 1em);
      line-height: 1;
      font-weight: 500;
      color: $blue-5;
      width: 10em;

      @media screen and (max-height: 500px) {
        // font-size: calc(#{$font-size-2}* 0.9em);
        // margin-top: 1em;
      }
    }

    &__description {
      @include bodyText;
      margin-top: 0.5em;
      line-height: 1;
    }

    &__button {
      position: relative;
      display: block;
      background-color: $blue-3;
      color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      text-decoration: none;
      padding: 0.5em 2em;
      text-align: center;
      margin: 2em 0;
      // opacity: 1;
      overflow: hidden;
      transition: all 0.2s ease;

      &:hover {
        &::before {
          position: absolute;
          transform: translateX(100%);
        }
      }

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: -100%;
        height: 100%;
        width: 100%;
        background-color: $blue-1;
        z-index: -1;
        transition: all 0.2s ease;
      }
    }

    &__thumbs {
      display: none;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .solutions {
    &--title {
      font-size: 4em;
    }

    &--product {
      padding: 2em 0em;
      height: auto;

      &_middle {
        .solutions--product__thumbs {
          &::before,
          &::after {
            background-color: $product2;
          }
        }
      }

      &_last {
        .solutions--product__thumbs {
          &::before,
          &::after {
            background-color: $product3;
          }
        }
      }

      &__header {
        margin-bottom: 2em;
      }

      &__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2em;
        grid-template-rows: 20vh auto auto auto;
      }

      &__strapline {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        margin-top: 0;
        font-size: 2.25em;
        line-height: 1.25;
        width: 70%;
      }

      &__description {
        font-size: 1.25em;
        grid-row: 1 / 3;
        grid-column: 2 / 3;
        line-height: 1.5;
        margin-top: 0;
      }

      &__button {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        display: inline-block;
        width: fit-content;
        margin-bottom: 0;
      }

      &__photo {
        margin-top: 2em;
        grid-row: 3 / 4;
        grid-column: 1 / 3;
      }

      &__thumbs {
        position: relative;
        display: block;
        grid-row: 4 / 5;
        grid-column: 1 / 3;
        // border: solid green;
        overflow: visible;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          height: 100%;
          width: 25vw;
          background-color: $product1;
          z-index: 2;
        }

        &::before {
          left: -25vw;
        }

        &::after {
          right: -25vw;
        }

        .glide__track {
          overflow: visible;
        }

        .glide__slides {
          overflow: visible;
        }

        .glide__slide {
          // overflow: visible;
          height: 100%;
          cursor: pointer;
          transition: 0.3s ease-in 50ms;

          img {
            @include imgSize;
          }

          &:hover {
            // border: solid blue;
            transition: 0.3s ease-out 50ms;

            transform: translateY(-15px);
          }
        }
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .solutions {
    &--product {
      height: auto;
      padding-bottom: 8em;

      &__body {
        grid-template-rows: auto auto auto auto;
      }

      &__strapline {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      &__description {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        padding-top: 1em;
        margin-top: 1em;
        width: 18em;
      }

      &__photo {
        grid-column: 2 / 3;
        grid-row: 1 / 4;
        margin-top: 0;

        img {
          height: auto;
        }
      }

      &__button {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        height: fit-content;
      }

      &__thumbs {
        grid-column: 1 / 3;
        grid-row: 4 / 5;
        margin-top: 6em;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-xlarge}*1em)) and (max-height: 800px) {
  .solutions {
  }
}
