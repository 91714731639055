html {
  // scroll-behavior: smooth;
  // cursor: none;
}

body {
  font-family: "Heebo", sans-serif;
}

.container {
  height: 100%;
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 1em;
}

.sectionHeader {
  width: 100%;
  padding: 0.5em 0;
  font-family: "Palanquin", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  color: $blue-5;
  border-bottom: solid #444444 1px;
  text-align: right;
}

.row {
  display: flex;
}

// .cursor {
//   display: none;
//   position: fixed;
//   border-radius: 50%;
//   pointer-events: none;
//   z-index: 666;

//   &_in {
//     width: 20px;
//     height: 20px;
//     background-color: $naranja;
//     transform: translate(-50%, -50%);
//   }

//   &_out {
//     width: 80px;
//     height: 80px;
//     border: solid $blue-5 1px;
//   }
// }

@media screen and (max-height: 600px) {
  body {
    font-size: 14px;
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .container,
  .sectionContainer {
    padding: 0 5em;
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  html {
    // cursor: none;
  }

  .cursor {
    // display: block;
  }

  .container {
    // padding: 0 3em;
  }
}
