.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $nav-height;
  background-color: rgba(white, 0.7);
  backdrop-filter: blur(15px);
  z-index: 99;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 1em;
    // border: solid red thin;
  }

  &--brand {
    height: 100%;
    width: 30%;
    // width: 12em;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &--links {
    list-style: none;
    display: none;

    &__item {
      cursor: pointer;
      margin-left: 2em;
      position: relative;

      &-line {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        width: 0;
        background-color: $naranja;
        transition: 0.3s ease 50ms;
      }

      a {
        text-decoration: none;
        color: $blue-5;
        font-weight: 400;
        font-family: "Palanquin", sans-serif;
        // letter-spacing: 1px;

        &:hover + div {
          width: 100%;
        }
      }
    }
  }

  &--burger {
    cursor: pointer;
    z-index: 99;

    div {
      background-color: $blue-4;
      width: 25px;
      height: 3px;
      margin: 3px;
    }
  }

  &--phone {
    // background-color: #1bd741;
    // background-color: $naranja;
    background-color: $blue-4;
    padding: 0.5em 1em;
    border-radius: 7px;

    a {
      text-decoration: none;
      color: white;
    }
  }

  &--hidden {
    position: fixed;
    background-color: $blue-4;
    height: 100vh;
    width: 100%;
    top: 0;
    left: -100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__list {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }

    &__list-item {
      margin-bottom: 2em;

      a {
        text-decoration: none;
        color: white;
        font-size: 1.5em;
        transition: all 0.3s ease 50ms;
        font-family: "Palanquin", sans-serif;

        &:hover {
          color: $naranja;
        }
      }
    }
  }
}

@media screen and (max-height: 500px) {
  .nav {
    // border-top: solid blue;
    height: 3em;
  }
}

@media screen and (min-width: calc(#{$bp-small}*1em)) {
  .nav {
    // border-top: solid blue;
  }
}
@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .nav {
    // border-top: solid orange;
    // height: auto;
    &--phone {
      display: none;
    }

    &--brand {
      width: 12em;
    }
  }
}
@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .nav {
    // border-top: solid green;

    &--links {
      display: flex;
      align-items: center;
      // border: solid orange thin;
    }

    &--whatsapp {
      display: inline-block;
      // border: solid red;
      // height: var(--size);
      height: 100%;
      // width: var(--size);

      &__img {
        display: flex;
        align-items: center;
        --size: 1.8em;
        margin-top: 0.25rem;
        // border: solid green thin;
        width: var(--size);
        height: var(--size);

        img {
          object-fit: contain;
          height: 100%;
          width: 100%;
        }
      }
    }

    &--burger {
      display: none;
    }
  }
}
@media screen and (min-width: calc(#{$bp-xlarge}*1em)) {
  .nav {
    // border-top: solid red;
  }
}
