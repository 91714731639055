.thumbsOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  padding: 3em 1em;
  background-color: rgba(black, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__container {
    position: relative;
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3em 1em 1em 1em;
    overflow: hidden;
  }

  &__screenshot {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  &__closeBtn {
    position: absolute;
    top: 1em;
    right: 1em;
    height: 1em;
    width: 1em;
    cursor: pointer;
    transition: 0.3s ease-out 50ms;

    &:hover {
      transform: scale(1.1);
    }

    img {
      @include imgSize;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .thumbsOverlay {
    &__container {
      height: 90vh;
      width: auto;
    }
  }
}
