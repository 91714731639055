.clients {
  background-color: $grey-pixi;
  background-color: white;
  padding: 6em 0;
  // overflow: hidden;

  .container {
    width: fit-content;
    // overflow: hidden;
  }

  &--text {
    font-size: calc(#{$font-size-2}* 1.2em);
    line-height: 1.25;
    color: $blue-5;
    color: #444444;
    font-weight: 300;

    span {
      color: $naranja;
      font-weight: 700;
    }
  }

  .glide {
    margin-top: 2em;
    padding: 0 1em;

    .container {
      max-width: 100%;
    }

    &__slides {
      display: flex;
      align-items: center;
    }

    &__slide {
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-height: 70px;
      }
    }

    #argus {
      opacity: 0.6;
    }

    #bioscan {
      opacity: 0.6;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .clients {
    &--text {
      margin-bottom: 2em;
    }

    .glide {
      .contaier {
        max-width: 90em;
      }
    }
  }
}
